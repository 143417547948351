/* light theme colors */
:root {
  --navbar-background-color: #EAEAEA;
  --graph-background-color: #f0f8ff;
  --graph-text-color: #000000;
  --main-background-color: #f3f6ff;
  --list-background-color: #d5dae7;
  --text-color: #000000;
  --hover-transform: rgba(0,0,0,0.05);
  --button-color: #ffffff;
  --button-color-active: #c0c0c0;
}

/* dark theme colors */
[data-theme="dark"] {
  --navbar-background-color: #222222;
  --graph-background-color: #f0f8ff;
  --graph-text-color: #000000;
  --main-background-color: #474747;
  --list-background-color: #292929;
  --text-color: #ffffff;
  --hover-transform: rgba(255, 255, 255, 0.25);
  --button-color: #ffffff;
  --button-color-active: #c0c0c0;
}

body {
  background-color: var(--main-background-color);
  color: var(--text-color);
}

.App-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  padding-left: 10vw;
  padding-right: 10vw;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.col-1 {
  flex: 1;
}

.col-2 {
  flex: 2;
}

.col-3 {
  flex: 3;
}

.col-4 {
  flex: 4;
}

.col-5 {
  flex: 5;
}

.col-6 {
  flex: 6;
}

.col-7 {
  flex: 7;
}

.col-8 {
  flex: 8;
}

.col-9 {
  flex: 9;
}

.col-10 {
  flex: 10;
}

.col-11 {
  flex: 11;
}

.col-12 {
  flex: 12;
}