.banner {
    max-height: 70vh;
    min-height: 70vh;
    padding: 40px;
    padding-left: 10vw;
    padding-right: 10vw;
}

.bannerProfile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bannerStatContainer {
    padding-top: 30px;
}

.bannerStatRow {
    padding: 0px 2vw 30px 2vw;
}

.bannerCareer {
    padding: 0px 2vw 0px 2vw;
}

.bannerCareer p{
    font-style: italic;
    font-size: 20px;
}

.bannerMainStats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bannerMainStats h2 {
    font-size: 6vw;
    margin:0;
}

.bannerMainStats p {
    font-size: 3vw;
    font-weight: 100;
    font-style: italic;
    margin: 0;
}

.bannerSubStatsContainer {
    border: 1px solid;
    width: 100%;
    min-height: 150px;
    padding: 0px 20px 0px 20px;
    margin: 0 2vw 0 2vw;
}

.bannerSubStatsContainer p {
    font-size: 1.5vw;
}

.playerBannerName h1{
    font-size: 3vw;
    margin:0;
}

.circleImgContainer {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 100%;
    border: 1px solid;
    margin: 10px;
}

.circleImgContainer img {
    height: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.playerImg {
    width: 25vw;
    height: 25vw;
}

.graphSelectorMain {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.graphSelectorMain button{
    padding: 6px;
    border: 1px solid;
    font-size: 20px;
    height: 40px;
}

.graphSelectorMain .active{
    font-weight: 600;
    background-color: var(--button-color-active);;
}

.graphSelectorMain .inactive{
    font-weight: 400;
    background-color: var(--button-color);
}


.graphSelectorMain button:hover{
    background-color: var(--button-color-active);
}

.playerGraphContainer {
    height: 85vh;
    width: 100vw;
    margin: 10px 5vw 10px 5vw;
    background-color: var(--graph-background-color);
    color: var(--graph-text-color);
    position: relative;
}

.playerProgressionGraphContainer {
    height: 100%;
    position: relative;
}

.playerProgressionLegendContainer {
    height: 50%;
}

.playerProgressionLegendBox {
    margin: 5px;
    padding: 0px 5% 0px 5%;
    border: 1px solid;
    position: relative;
}

.playerProgressionHistogramContainer {
    height: 50%;
}

.graphComponent {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
}

.pbpContainer {
    width: 100%;
    height: 100%;
}

.pbpSelectContainer {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.graph-title {
    font-size: 20px;
    font-weight: 700;
}

.pbpSliderContainer {
    width: 100%;
    height: 10%;
}

.pbpSliderBrush .selection{
    fill-opacity: 0.5;
}

.pbpSliderBrush .handle{
    fill: #000;
    stroke: #000;
    stroke-width: 1.5px;
}

.pbpHeatmapContainer {
    width: 100%;
    height: 80%;
}

.pbpInfoContainer {
    display: flex;
    align-items: center;
}

.pbpLegend {
    width: 80%;
    height: auto;
    border: 2px solid;
    text-align: center;
    margin: 2%;
    padding: 2%;
}

.pbpLegendHead {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 20%;
}

.pbpLegendHead p {
    font-size: 20px;
    margin: 2px;
    font-weight: 600;
}

.pbpLegendInfo {
    display: flex;
    justify-content:baseline;
    align-items: center;
    text-align: left;
    padding: 0px 20px 10px 20px;
}

.pbpLegendInfo p {
    font-size: 20px;
    margin: 0px;
    font-weight: 400;
}

.pbpLegendColor {
    height: 20px;
    width: 20px;
    padding: 2px;
}

.pbpScoreboard {
    width: 80%;
    height: 32%;
    border: 2px solid;
    text-align: center;
    margin: 2%;
}

.pbpScoreboardHead {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 20%;
}

.pbpScoreboardHead p {
    font-size: 20px;
    margin: 2px;
    font-weight: 600;
}

.pbpScoreboardTimeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 5px;
    height: 20%;
}

.pbpScoreboardTimeContainer p {
    font-size: 20px;
    margin: 2px;
}

.pbpScoreboardTime {
    width: 10%;
    border: 2px solid;
}

.pbpScoreboardScore {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.pbpScoreboardNumber {
    border: 2px solid;
    font-weight: 800;
    font-size: 25px;
    height: 60%;
}

.pbpTooltip {
    width: 80%;
    height: 60%;
    border: 2px solid;
    text-align: center;
    margin: 2%;
}

.pbpTooltipHead {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 15%;
}

.pbpTooltipHead p {
    font-size: 20px;
    margin: 2px;
    font-weight: 600;
}

.pbpTooltipInfo {
    display: flex;
    justify-content:baseline;
    align-items: baseline;
    text-align: left;
    height: 20%;
    padding: 0px 20px 10px 20px;
}

.pbpTooltipInfo p {
    font-size: 20px;
    margin: 0px;
    font-weight: 400;
}

.pbpTooltipSubtitle {
    display: flex;
    justify-content:baseline;
    align-items: baseline;
    text-align: left;
    height: 20%;
    padding: 0px 20px 0px 20px;
}

.pbpTooltipSubtitle p {
    font-size: 15px;
    margin: 0px;
    font-weight: 100;
    font-style: italic;
}

.centerSvg {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.careerLegendItem {
    padding: 5px 0px 5px 0px;
}
.careerLegendName {
    width: 20%;
}

.careerLegendColor {
    margin: 2px;
    min-width: 25px;
    height: 25px;
}

.playerSimilarGraphContainer {
    height: 100%;
}

.playerSimilarLegendContainer {
    height: 50%;
}

.playerSimilarLegendBox {
    margin: 5px;
    padding: 0px 5% 0px 5%;
    border: 1px solid;
}

.playerSimilarPCAContainer {
    height: 50%;
    display: flex;
}

.joyrideIcon {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    padding: 5px;
}

.joyrideIcon:hover {
    opacity: 0.75;
}

#progressionJoyride {
    position: absolute;
    right: 0px
}

#pbpJoyride {
    position: absolute;
    right: 0px
}

#progressionGraphOverlay {
    width: 100%;
    height: 80%;
    position: absolute;
    z-index: -1;
}

#progressionBrushOverlay {
    width: 100%;
    height: 20%;
    position: absolute;
    top:80%;
    z-index: -1;
}

.pcaXaxis .domain {
    stroke: none;
}

.pcaYaxis .domain {
    stroke: none;
}

.loadings-label {
    font-size: 18px;
    font-weight: 600;
}

.similarLegendItem {
    padding: 5px 0px 5px 0px;
}
.similarLegendName {
    width: auto;
}

.similarLegendColor {
    margin: 0px;
    width: 25px;
    height: 25px;
}

#radarChartContainer {
    background: rgba(255, 255, 255, 0.8);
    width: 250px;
    height: 250px;
    border: 1px solid #ccc;
    padding: 10px;
    pointer-events: none;
    display: none;    
}