.Home-page {
    align-items: center;
    text-align: center;
}

.test {
  background-color: beige;
}

.header-main {
  padding: 100px;
}

.header-main-italic {
  font-size: 30px;
  font-style: italic;
  margin: 0px;
}

.header-main-text {
  font-size: 150px;
  font-weight: 900;
  margin: 10px;
  color: #df7128;
}

.header-main-subtext {
  font-size: 18px;
  font-style: italic;
  margin-top: 30px;
}